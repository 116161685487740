import React from 'react';
import './css/Login.css';

const Login = () => {
	return (
		<>
			<div className="content mt-5">
				<div className="container login-shadow" >
					<div className="row">
						<div className="col-md-6 col-sm-12 my-auto">
							<div className="card marquee login-marquee">
								<div className="marquee-text">
									<p>
										<span className='login-notice'>NOTICE:-</span>
										<br /><br />
										<b>About Datacare Consultancy Service</b><br />DATACARE is a software development house with proven expertise in systems and application developments, which is used to offer solutions across vertical segments, Software Development Consulting, Web Architecture & IT Infrastructure Management.<br /><br /><b>Our Mission</b><br />DATACARE believes in quality software development by implementing a comprehensive quality policy to deliver results on time and within budget. It is a software development house with strong management and software development processes and state-of-art infrastructure.<br /><br /><b>THANK YOU</b>
										<br />
										<b>......................................................................</b>
									</p>
								</div>
							</div>
						</div>
						<div className="col-md-6 col-sm-12 my-5">
							<div className="row justify-content-center">
								<div className="col-md-8 border bg-light" style={{ boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}>
									<div className="my-4 mx-auto">
										<h3 style={{ color: "#18ba60" }}>LOGIN FORM</h3>
									</div>
									<form action="login_process.php" method="POST">
										<div className="mb-3 text-start ">
											<label for="SelectGroup" className="form-label">Financial Year</label>
											<select className="custom-select form-control" id="SelectGroup" style={{ width: "400px" }}>
												<option selected>Choose...</option>
												<option value="1">2022-2023</option>
												<option value="2">2023-2024</option>
											</select>
										</div>
										<div className="mb-3 text-start">
											<label for="software_code" className="form-label">Software Code</label>
											<input type="text" className="form-control" id="software_code" name="software_code" />
										</div>
										<div className="mb-3 text-start">
											<label for="username" className="form-label">Username</label>
											<input type="text" className="form-control" id="username" name="username" />
										</div>
										<div className="mb-3 text-start">
											<label for="InputPassword" className="form-label">Password</label>
											<input type="password" className="form-control" id="InputPassword" name="password" />
										</div>
										<div className="mb-3 d-flex justify-content-between align-items-center">
											<button type="submit" name="login" className="btns btn">Login</button>

											<span className="d-flex align-items-center">Create an account? <a href="./Signup" style={{ color: "#18ba60", marginLeft: "3px" }}>Register</a></span>

										</div>
										<div className="mb-3 text-start">
											<a href="#" className="text-decoration-none" style={{ color: "#15a756;" }}><span>Forgot Password? </span></a>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Login