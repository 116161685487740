import React from 'react';
import Vproducts from './Vproducts';
// import logistics1 from '../asset/logistic1.jpg';
import fleetmain from '../asset/fleetmaintenance.jpg';
import logistics2 from '../asset/logistic2.jpg';
import './Allproduct.css';
import { Link } from 'react-router-dom';

function Fleetmaintenance() {
  return (
<>
      <div class="mb-5">
        <div class="container">
          <div class="pt-5 pb-1">
            <h3 class="fw-bold text-center main-color" >FLEET MAINTENANCE SOFTWARE</h3>
            <hr class="mb-5 main-color" />
            <div class="row">
              <div class="col-lg-6 col-md-12 ps-2 mt-3">
                <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
                  <div class="carousel-inner border-green" >
                    <div class="carousel-item active">
                      <img src={fleetmain} class="d-block w-100" alt="..." /> 
                      </div>             
                    <div class="carousel-item">
                      <img src={logistics2} class="d-block w-100" alt="..." />  
                      </div>            
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 mb-3 mt-3">
                <div class="fs-4 mb-3">
                  <span class="text-decoration">Fleet Maintenance</span>
                </div>
                <ul class="font-size">
                  <li>Simplify vehicle maintenance tasks.</li>
                  <li>Proactive maintenance scheduling.</li>
                  <li>Maintenance history tracking.</li>
                  <li>Compliance tracking.</li>
                  <li>Downtime minimization.</li>
                </ul>
                <p class="text text-view" >Our Fleet Maintenance Software is designed to simplify vehicle maintenance tasks and prolong asset lifespan.It enables proactive maintenance scheduling, reducing the risk of unexpected breakdowns.Track maintenance history, manage service contracts, and automate work orders for efficient operations.Ensure compliance with regulatory standards and minimize downtime with our intuitive maintenance solution.
                </p>
                <Link class="btn btn-success" type="button" to="/fleetmaintenancep"><i class="bi-cart-fill me-1"></i>View Price</Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <h2 class="fw-bold fst-italic  text-center main-color" >FEATURES</h2>
        <hr class="border border-success"/>
          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 py-5">
            <div class="col d-flex flex-column align-items-center">
              <h1 class="ri-checkbox-circle-fill text-success"></h1>
              <div class="text-center">
                <h4 class="fw-bold mb-0">Vehicle Master</h4>
                <p>Centralized vehicle database for maintenance tracking.</p>
              </div>
            </div>
            <div class="col d-flex flex-column align-items-center">
              <h1 class="ri-checkbox-circle-fill text-success"></h1>
              <div class="text-center">
                <h4 class="fw-bold mb-0">Inventory & Purchase</h4>
                <p>Streamlined management of parts inventory.</p>
              </div>
            </div>
            <div class="col d-flex flex-column align-items-center">
              <h1 class="ri-checkbox-circle-fill text-success"></h1>
              <div class="text-center">
                <h4 class="fw-bold mb-0">Vendor Detail</h4>
                <p>Organized vendor information for collaboration.</p>
              </div>
            </div>
            <div class="col d-flex flex-column align-items-center">
              <h1 class="ri-checkbox-circle-fill text-success"></h1>
              <div class="text-center">
                <h4 class="fw-bold mb-0">Tyre Management</h4>
                <p> Efficient tracking of tire usage and replacements.</p>
              </div>
            </div>
            <div class="col d-flex flex-column align-items-center">
              <h1 class="ri-checkbox-circle-fill text-success"></h1>
              <div class="text-center">
                <h4 class="fw-bold mb-0">Maintenance Card</h4>
                <p>Simplified documentation for maintenance.</p>
              </div>
            </div>
            <div class="col d-flex flex-column align-items-center">
              <h1 class="ri-checkbox-circle-fill text-success"></h1>
              <div class="text-center">
                <h4 class="fw-bold mb-0">Service Schedule</h4>
                <p>Efficient planning of maintenance schedules.</p>
              </div>
            </div>
            <div class="col d-flex flex-column align-items-center">
              <h1 class="ri-checkbox-circle-fill text-success"></h1>
              <div class="text-center">
                <h4 class="fw-bold mb-0">Vendor Ledger</h4>
                <p>Accurate tracking of transactions with vendors.</p>
              </div>
            </div>
            <div class="col d-flex flex-column align-items-center">
              <h1 class="ri-checkbox-circle-fill text-success"></h1>
              <div class="text-center">
                <h4 class="fw-bold mb-0">Reports</h4>
                <p>Analytics for maintenance <br/>evaluation.</p>
              </div>
            </div>
          </div>
          <hr class="border border-success"/>
          </div>
          <div class="container mb-5">
            <h2 class="fw-bold fst-italic  text-center main-color">OUR PRODUCTS</h2>
            <hr class="border border-success" />
          </div>
          <Vproducts />
        </>
        )
}

        export default Fleetmaintenance