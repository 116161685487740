import React from 'react';
import './css/Airtheme.css';

const Airtheme = () => {
	return (
		<>
			< section >
				<div class='air air1'></div>
				<div class='air air2'></div>
				<div class='air air3'></div>
				<div class='air air4'></div>
			</section >
		</>
	)
}

export default Airtheme