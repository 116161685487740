// import React from 'react';
import './css/Contact.css';
import { Component, React } from 'react';




class Contact extends Component {
  state = {
    username: '',
    email: '',
    phone: '',
    message:'',
    emailStatus: ''
  };

  // handle input change
  handleChange = input => e => {
    this.setState({ [input]: e.target.value });
  }

  // handle form submission
  submitForm = (e) => {
    e.preventDefault();
    const { username, email,phone, message } = this.state;

    // create a new XMLHttpRequest
    var xhr = new XMLHttpRequest();

    // listener to handle server response
    xhr.addEventListener('load', () => {
      this.setState({
        emailStatus: xhr.responseText
      });
    });

    // open the request with the verb and the url
    xhr.open('GET', `https://datacaresales.com/api/contact.php?sendto=${encodeURIComponent(email)}&username=${encodeURIComponent(username)}&phone=${encodeURIComponent(phone)}&message=${encodeURIComponent(message)}`);

    // send the request
    xhr.send();

    // reset form fields
    this.setState({
      username: '',
      email: '',
      phone: '',
      message:''
    });
    alert('Are you sure submitted ?');
  }
  render() {
    const { username, email,phone, message, emailStatus } = this.state;
    return (
      <>
        <div class="container my-5">
          <div class="contact__wrapper shadow-lg mt-n9">
            <div class="row">
              <div class="col-lg-5 contact-info__wrapper gradient-brand-color p-5 order-lg-2">
                <h3 class="text-white mb-5">Get in Touch</h3>
                <ul class="contact-info__list list-style--none position-relative z-index-101">
                  <li class="mb-4 pl-4 text-white">
                    <span class="position-absolute"><i class="fas fa-envelope"></i></span> sales@datacaresales.com
                  </li>
                  <li class="mb-4 pl-4 text-white">
                    <span class="position-absolute"><i class="fas fa-phone"></i></span> (0120) 4124140 <br />
                    +91-8010020036
                  </li>
                  <li class="mb-4 pl-4 text-white">
                    <span class="position-absolute"><i class="fas fa-map-marker-alt"></i></span> Datacare Consultancy Services.
                    <br />
                    B-4 Sector-63 Noida 201309,India
                    <br />
                    <div class="mt-3">
                      <a href="https://maps.app.goo.gl/pCEpbT4qtR2kBCmFA" target="_blank" class="text-link link--right-icon text-white">Get directions <i class="link__icon fa fa-directions"></i></a>
                    </div>
                  </li>
                </ul>
              </div>

              <div class="col-lg-7 contact-form__wrapper p-5 order-lg-1">
                <form onSubmit={this.submitForm}>
                  <div class="row">
                    <div class="col-sm-6 mb-3">
                      <div class="form-group">
                        <label class="required-field" for="userName">User Name</label>
                        <input type="text" class="form-control" id="userName" name="username" placeholder="Enter Your Username" onChange={this.handleChange('username')}  value={username} required autoFocus/>
                      </div>
                    </div>

                    <div class="col-sm-6 mb-3">
                      <div class="form-group">
                        <label class="required-field" for="email">Email</label>
                        <input type="text" class="form-control" id="email" name="email" placeholder="username@gmail.com" onChange={this.handleChange('email')}  value={email} required />
                      </div>
                    </div>

                    <div class="col-sm-6 mb-3">
                      <div class="form-group">
                        <label for="phone">Phone Number</label>
                        <input type="text" maxlength="10" class="form-control" id="phone" name="phone" placeholder="XXXXXXXXXX" onChange={this.handleChange('phone')}  value={phone} required />
                      </div>
                    </div>

                    <div class="col-sm-12 mb-3">
                      <div class="form-group">
                        <label class="required-field" for="message">How can we help?</label>
                        <textarea class="form-control" id="message" name="message" rows="4" placeholder="Hi there, I would like to....." onChange={this.handleChange('message')}  value={message}></textarea>
                      </div>
                    </div>

                    <div class="col-sm-12 mt-2 mb-3">
                      <button type="submit" name="submit" class="btn btn-outline-success">Submit</button>

                    </div>
                    {emailStatus && <h5 className='text-danger'>{emailStatus}</h5>}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

      </>
    )
  }
}

export default Contact
