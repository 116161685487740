import React from 'react'
import Vproducts from './Vproducts';
// import logistics1 from '../asset/logistic1.jpg';
import mobile from '../asset/mobile.jpg';
import logistics2 from '../asset/logistic2.jpg';
import './Allproduct.css';
import { Link } from 'react-router-dom';



function Mobile() {
  return (
<>

<div class="mb-5">
  <div class="container">
    <div class="pt-5 pb-1">
      <h3 class="fw-bold text-center main-color">MOBILE APPLICATION</h3>
      <hr class="mb-5 main-color" />
      <div class="row">
        <div class="col-lg-6 col-md-12 ps-2 mt-3">
          <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner border-green">
              <div class="carousel-item active">
              <img src={mobile} class="d-block w-100" alt="..." />

              </div>
              <div class="carousel-item">
              <img src={logistics2} class="d-block w-100" alt="..." />

              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 mb-3 mt-3">
          <div class="fs-4 mb-3">
            <span class="text-decoration">Mobile</span>
          </div>
          <ul class="font-size">
            <li>Access to software functionality on mobile devices.</li>
            <li>Real-time notifications.</li>
            <li>Offline functionality.</li>
            <li>Intuitive user interfaces.</li>
            <li>Increased mobility and flexibility.</li>
          </ul>
          <p class="text text-view">Our Mobile Application extends the functionality of our software solutions to your fingertips.Access critical business information, track operations, and stay connected with your team from anywhere.Features include real-time notifications, offline functionality, and intuitive user interfaces for seamless user experience.Empower your workforce with mobility and flexibility while enhancing productivity and responsiveness.
          </p>
          <Link class="btn btn-success" type="button" to="/Mobilep"><i class="bi-cart-fill me-1"></i>View Price</Link>
        </div>
      </div>
    </div>
    </div>
</div>

<div class="container">
  <h2 class="fw-bold fst-italic  text-center main-color" >FEATURES</h2>
  <hr class="border border-success"/>
  <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 py-5">
    <div class="col d-flex flex-column align-items-center">
      <h1 class="ri-checkbox-circle-fill text-success"></h1>
      <div class="text-center">
        <h4 class="fw-bold mb-0">Real-Time Syncing</h4>
        <p>Stay up-to-date <br/>instantly.</p>
      </div>
    </div>
    <div class="col d-flex flex-column align-items-center">
      <h1 class="ri-checkbox-circle-fill text-success"></h1>
      <div class="text-center">
        <h4 class="fw-bold mb-0">Customization Options</h4>
        <p>Tailor the app to fit <br/>your needs.</p>
      </div>
    </div>
    <div class="col d-flex flex-column align-items-center">
      <h1 class="ri-checkbox-circle-fill text-success"></h1>
      <div class="text-center">
        <h4 class="fw-bold mb-0">Intuitive User Interface</h4>
        <p>Enjoy seamless <br/>navigation.</p>
      </div>
    </div>
    <div class="col d-flex flex-column align-items-center">
      <h1 class="ri-checkbox-circle-fill text-success"></h1>
      <div class="text-center">
        <h4 class="fw-bold mb-0">Updates and Support</h4>
        <p>Access ongoing improvements & assistance.</p>
      </div>
    </div>
    <div class="col d-flex flex-column align-items-center">
      <h1 class="ri-checkbox-circle-fill text-success"></h1>
      <div class="text-center">
        <h4 class="fw-bold mb-0">Analytics and Reporting</h4>
        <p>Gain valuable insights into your usage.</p>
      </div>
    </div>
    <div class="col d-flex flex-column align-items-center">
      <h1 class="ri-checkbox-circle-fill text-success"></h1>
      <div class="text-center">
        <h4 class="fw-bold mb-0">Offline Functionality</h4>
        <p>Stay productive even without internet.</p>
      </div>
    </div>
    <div class="col d-flex flex-column align-items-center">
      <h1 class="ri-checkbox-circle-fill text-success"></h1>
      <div class="text-center">
        <h4 class="fw-bold mb-0">Integration with 3rd-party Services</h4>
        <p>Connect with your favorite tools.</p>
      </div>
    </div>
    <div class="col d-flex flex-column align-items-center">
      <h1 class="ri-checkbox-circle-fill text-success"></h1>
      <div class="text-center">
        <h4 class="fw-bold mb-0">Cross-Platform Compatibility</h4>
        <p>Access the app across devices easily.</p>
      </div>
    </div>
  </div>
  <hr class="border border-success"/>
</div>
<div class="container mb-5">
				<h2 class="fw-bold fst-italic  text-center main-color">OUR PRODUCTS</h2>
				<hr class="border border-success" />
			</div>
			<Vproducts />
</>
  )
}

export default Mobile