// import React from 'react';
import './css/Slider.css';
import 'animate.css';
// import { useState } from 'react';
import { useState, React } from 'react';
// import Floaticons from './Floaticons';



const Slider = () => {
	const initialFormData = {
		number: ''
	};
	const [formData, setFormData] = useState(initialFormData);
	const [message, setMessage] = useState('');
	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			const response = await fetch('https://datacaresales.com/api/number.php', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(formData),
			});

			const responseData = await response.json();

			if (responseData.success) {
				setMessage(responseData.success);
				alert('Successfully submitted');
				setFormData(initialFormData); // Reset form data to initial state
			} else {
				setMessage(responseData.error || responseData.message);
			}
		} catch (error) {
			console.error('Error:', error);
			setMessage('An error occurred, please try again.');
		}
	};
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};
	const handleClick = () => {
		var phoneNumber = "8010020036"; // Replace with your WhatsApp number

		var whatsappUrl = "https://wa.me/" + phoneNumber;
		window.open(whatsappUrl, "_blank");
	};

	return (
		<>

			<div class="row">
				<div class="col-lg-6">
					<div className="section full-height over-hide px-4 px-sm-0">
						<div className="container ">
							<div className="row full-height justify-content-center">
								<div className="col-lg-10 col-xl-8 align-self-center padding-tb">
									<h2 class="fw-light animate__animated animate__backInDown">We Provide Solution</h2>
									<div class="wrapper">
										<div class="static-txt">For</div>
										<ul class="dynamic-txts">
											<li><span className='spann'>Business...</span></li>
											<li><span className='spann'>Retailers...</span></li>
											<li><span className='spann'>Distributor...</span></li>
											<li><span className='spann'>Transporter...</span></li>
											<li><span className='spann'>Logistics...</span></li>
											<li><span className='spann'>Manufacturers...</span></li>
										</ul>
									</div>
									<p className='para text-dark '>We offer advanced technological and digital transformation services to the business for more than a decade.</p>
									<span className='stext'>Disclaimer: Frontpage's content is not financial advice. Learn more</span>
									<form onSubmit={handleSubmit}>
										<div className=' box'>

											<div className='d-flex box-inner'>
												<span className='stext'>+91 |</span>
												<input className="stext" type="number" placeholder="  Phone Number" id="email" name="number" onChange={handleChange} value={formData.number} required />
												<button type="submit" name="mail" className="btn text-white btn-success" onClick={handleClick}>SignUp</button>
											</div>
											{message && <h5 className='text-danger' hidden>{message}</h5>}
											{/* <div class="icons">
												<a href="https://www.instagram.com/datacaresales/" target="_blank"><i class="fab fa-instagram " id='insta'></i></a>
												<a href="mailto:datacare1998@gmail.com"><i class="fas fa-envelope text-warning"></i></a>
												<a href="https://www.facebook.com/people/Datacare-Consultancy-Services/61559689965671/" target="_blank"><i class="fab fa-facebook text-primary"></i></a>
												<a class="open-button" onClick={handleClick}><i class="fab fa-whatsapp"></i></a>
											</div> */}
								
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-6">
					<div className="section full-height over-hide px-4 px-sm-0">
						<div className="container">
							<div className="row full-height justify-content-center">
								<div className="col-lg-10 col-xl-8 align-self-center padding-tb">
									<div className="section mx-auto text-center slider-height-padding">
										<input className="checkbox frst" type="radio" id="slide-1" name="slider" checked />
										<label for="slide-1"></label>
										<input className="checkbox scnd" type="radio" name="slider" id="slider-2" />
										<label for="slider-2"></label>
										<input className="checkbox thrd" type="radio" name="slider" id="slider-3" />
										<label for="slider-3"></label>
										<input className="checkbox foth" type="radio" name="slider" id="slider-4" />
										<label for="slider-4"></label>
										<ul className='ull'>
											<li>

											</li>
											<li>

											</li>
											<li>

											</li>
											<li>

											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</>
	)
}

export default Slider