import React from 'react';
// import logistics1 from '../asset/logistic1.jpg';
import manufacturing from '../asset/manufacturing.jpg';
import logistics2 from '../asset/logistic2.jpg';
import Vproducts from './Vproducts';
import './Allproduct.css';
import { Link } from 'react-router-dom';

const Manufacturing = () => {
	return (
		<>
			<div class="mb-5">
				<div class="container">
					<div class="pt-5 pb-1">
						<h3 class="fw-bold text-center  main-color" >MANUFACTURING MANAGEMENT SOFTWARE</h3>
						<hr class="mb-5 main-color" />
						<div class="row">
							<div class="col-lg-6 col-md-12 ps-2 mt-3">
								<div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
									<div class="carousel-inner border-green">
										<div class="carousel-item active">
											<img src={manufacturing} class="d-block w-100" alt="..." />
										</div>
										<div class="carousel-item">
											<img src={logistics2} class="d-block w-100" alt="..." />
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-6 col-md-12 mb-3 mt-3">
								<div class="fs-4 mb-3">
									<span class="text-decoration">Manufacturing Management</span>
								</div>
								<ul class="font-size">
									<li>Streamline production processes.</li>
									<li>Inventory management.</li>
									<li>Production scheduling.</li>
									<li>Quality control.</li>
									<li>Predictive maintenance.</li>
								</ul>
								<p class="text text-view" >Our Manufacturing Management Software empowers you to streamline production processes and maximize output.It covers inventory management, production scheduling, and quality control, ensuring smooth operations.Features like predictive maintenance and resource allocation help optimize resource utilization and minimize downtime.Gain actionable insights into your manufacturing operations with comprehensive analytics and reporting tools.
								</p>
								<Link class="btn btn-success" type="button" to="/manufacturingp"><i class="bi-cart-fill me-1"></i>View Price</Link>
							</div>
						</div>
					</div>
				</div>
			</div>



			<div class="container">
				<h2 class="fw-bold fst-italic  text-center main-color" >FEATURES</h2>
				<hr class="border border-success" />
				<div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 py-5">
					<div class="col d-flex flex-column align-items-center">
						<h1 class="ri-checkbox-circle-fill text-success"></h1>
						<div class="text-center">
							<h4 class="fw-bold mb-0">Master</h4>
							<p>Comprehensive data management for all manufacturing processes.</p>
						</div>
					</div>
					<div class="col d-flex flex-column align-items-center">
						<h1 class="ri-checkbox-circle-fill text-success"></h1>
						<div class="text-center">
							<h4 class="fw-bold mb-0">Production</h4>
							<p>Streamlined monitoring and optimization of production activities.</p>
						</div>
					</div>
					<div class="col d-flex flex-column align-items-center">
						<h1 class="ri-checkbox-circle-fill text-success"></h1>
						<div class="text-center">
							<h4 class="fw-bold mb-0">Invoice & Order</h4>
							<p>Efficient handling of invoicing and order processing.</p>
						</div>
					</div>
					<div class="col d-flex flex-column align-items-center">
						<h1 class="ri-checkbox-circle-fill text-success"></h1>
						<div class="text-center">
							<h4 class="fw-bold mb-0">Shipping Planning</h4>
							<p>Seamless planning and coordination for shipping logistics.</p>
						</div>
					</div>
					<div class="col d-flex flex-column align-items-center">
						<h1 class="ri-checkbox-circle-fill text-success"></h1>
						<div class="text-center">
							<h4 class="fw-bold mb-0">Purchase Order</h4>
							<p>Simplified management of procurement processes.</p>
						</div>
					</div>
					<div class="col d-flex flex-column align-items-center">
						<h1 class="ri-checkbox-circle-fill text-success"></h1>
						<div class="text-center">
							<h4 class="fw-bold mb-0">Payroll</h4>
							<p>Automated payroll management for manufacturing workforce.</p>
						</div>
					</div>
					<div class="col d-flex flex-column align-items-center">
						<h1 class="ri-checkbox-circle-fill text-success"></h1>
						<div class="text-center">
							<h4 class="fw-bold mb-0">Accounts</h4>
							<p>Financial management tailored for manufacturing.</p>
						</div>
					</div>
					<div class="col d-flex flex-column align-items-center">
						<h1 class="ri-checkbox-circle-fill text-success"></h1>
						<div class="text-center">
							<h4 class="fw-bold mb-0">Reports</h4>
							<p>Insights for informed <br />decisions.</p>
						</div>
					</div>
				</div>
				<hr class="border border-success" />
			</div>
			<div class="container mb-5">
				<h2 class="fw-bold fst-italic  text-center main-color" >OUR PRODUCTS</h2>
				<hr class="border border-success" />
			</div>
			<Vproducts />

		</>
	)
}

export default Manufacturing