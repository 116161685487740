import React from 'react'
import Vproducts from './Vproducts';
import logistics1 from '../asset/logistic1.jpg';
import logistics2 from '../asset/logistic2.jpg';
// import Vproducts from './Vproducts';
import './Allproduct.css';
import { Link } from 'react-router-dom';

const Partload = () => {
	return (
		<>
			<div class="mb-5">
				<div class="container">
					<div class="pt-5 pb-1">
						<h3 class="fw-bold text-center main-color" >PART LOAD TRANSPORT SOFTWARE</h3>
						<hr class="mb-5 main-color"  />
						<div class="row">
							<div class="col-lg-6 col-md-12 ps-2 mt-3">
								<div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
									<div class="carousel-inner border-green" >
										<div class="carousel-item active">
										<img src={logistics1} class="d-block w-100" alt="..." />
										</div>
										<div class="carousel-item">
											<img src={logistics2} class="d-block w-100" alt="..." />
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-6 col-md-12 mb-3 mt-3">
								<div class="fs-4 mb-3">
									<span class="text-decoration">Part Load Transport</span>
								</div>
								<ul class='font-size'>
									<li>Efficient part load transport management.</li>
									<li>Dynamic route planning.</li>
									<li>Load consolidation.</li>
									<li>Real-time tracking and reporting.</li>
									<li>Cost optimization.</li>
								</ul>
								<p class="text text-view" >Designed for efficient part load transport management, our software optimizes resource utilization and minimizes costs.It offers dynamic route planning, load consolidation, and automated dispatching capabilities.With real-time tracking and reporting features, you can monitor shipments and make informed decisions on the fly.
								</p>
								<Link class="btn btn-success" type="button" to="/partloadp"><i class="bi-cart-fill me-1"></i>View Price</Link>
							</div>
						</div>
					</div>
				</div>
			</div>


			<div class="container">
				<h2 class="fw-bold fst-italic  text-center main-color">FEATURES</h2>
				<hr class="border border-success" />
				<div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 py-5">
					<div class="col d-flex flex-column align-items-center">
						<h1 class="ri-checkbox-circle-fill text-success"></h1>
						<div class="text-center">
							<h4 class="fw-bold mb-0">Goods Booking</h4>
							<p>Reserve space efficiently for partial shipments.</p>
						</div>
					</div>
					<div class="col d-flex flex-column align-items-center">
						<h1 class="ri-checkbox-circle-fill text-success"></h1>
						<div class="text-center">
							<h4 class="fw-bold mb-0">Manifest Challan</h4>
							<p>Simplify documentation for tracking partial cargo movements.</p>
						</div>
					</div>
					<div class="col d-flex flex-column align-items-center">
						<h1 class="ri-checkbox-circle-fill text-success"></h1>
						<div class="text-center">
							<h4 class="fw-bold mb-0">Pending Stocks</h4>
							<p>Efficiently handle partial load inventory in warehouses.</p>
						</div>
					</div>
					<div class="col d-flex flex-column align-items-center">
						<h1 class="ri-checkbox-circle-fill text-success"></h1>
						<div class="text-center">
							<h4 class="fw-bold mb-0">Arrivals</h4>
							<p>Manage and monitor incoming partial shipments seamlessly.</p>
						</div>
					</div>
					<div class="col d-flex flex-column align-items-center">
						<h1 class="ri-checkbox-circle-fill text-success"></h1>
						<div class="text-center">
							<h4 class="fw-bold mb-0">Delivery</h4>
							<p>Ensure timely and secure delivery of partial loads.</p>
						</div>
					</div>
					<div class="col d-flex flex-column align-items-center">
						<h1 class="ri-checkbox-circle-fill text-success"></h1>
						<div class="text-center">
							<h4 class="fw-bold mb-0">Local Challan</h4>
							<p>Facilitate local transportation documentation for partial shipments.</p>
						</div>
					</div>
					<div class="col d-flex flex-column align-items-center">
						<h1 class="ri-checkbox-circle-fill text-success"></h1>
						<div class="text-center">
							<h4 class="fw-bold mb-0">Payment Collection</h4>
							<p>Collect payments promptly for part-load services.</p>
						</div>
					</div>
					<div class="col d-flex flex-column align-items-center">
						<h1 class="ri-checkbox-circle-fill text-success"></h1>
						<div class="text-center">
							<h4 class="fw-bold mb-0">Reports</h4>
							<p>Performance insights for <br />decisions.</p>
						</div>
					</div>
				</div>
			</div>
			<div class="container mb-5">
				<h2 class="fw-bold fst-italic  text-center main-color" >OUR PRODUCTS</h2>
				<hr class="border border-success" />
			</div>
			<Vproducts />
		</>
	)
}

export default Partload