import React from 'react';
import './Allprice.css';

function Fullloadp() {
	return (
		<>
			<div class="container mt-5 mb-5">
				<div class="round_div head-width">
					<div class="first p-3 fs-5 fw-bold active main-head mb-3 text-center"><span>FULL LOAD TRANSPORT SOFTWARE PRICELIST</span></div>
				</div>
				<h2 class="text-center">India & South Asia (₹)</h2>
				<p class="text-center">India, Bangladesh, Sri Lanka, Nepal,</p>
			</div>
			<div class="container mb-5">

				<div class="pricing">
					<div class="plan">
						<h2>BASIC EDITION</h2>
						<div class="price">₹ 2150/year</div>
						<ul class="features">
							<li><i class="fas fa-check-circle"></i> Master</li>
							<li><i class="fas fa-check-circle"></i> Goods Bookings</li>
							<li><i class="fas fa-check-circle"></i> Manifest Challan</li>
							<li><i class="fas fa-check-circle"></i> Lorry Hire/Way Bill</li>
							<li><i class="fas fa-times-circle"></i> Lorry Arrival</li>
							<li><i class="fas fa-times-circle"></i> Godown Management</li>
							<li><i class="fas fa-times-circle"></i> Payments</li>
							<li><i class="fas fa-times-circle"></i> Billing</li>
							<li><i class="fas fa-check-circle"></i> Reports</li>
						</ul>
						<button>Buy Now</button>
					</div>
					<div class="plan popular">
						<span className='px-2'>Most Popular</span>
						<h2>PLATINUM EDITION</h2>
						<div class="price">₹ 3500/year</div>
						<ul class="features">
							<li> <i class="fas fa-check-circle"></i> Master Entry</li>
							<li><i class="fas fa-check-circle"></i> Goods Bookings</li>
							<li><i class="fas fa-check-circle"></i> Loory Hire/Challan</li>
							<li><i class="fas fa-check-circle"></i> Pending POD</li>
							<li><i class="fas fa-check-circle"></i> Broker Settlement</li>
							<li><i class="fas fa-check-circle"></i> Party Billng</li>
							<li><i class="fas fa-check-circle"></i> Payment Collection</li>
							<li><i class="fas fa-check-circle"></i> Reports</li>
							<li><i class="fas fa-check-circle"></i> Reports</li>
						</ul>
						<button>Buy Now</button>
					</div>
					<div class="plan">
						<h2>GOLD EDITION</h2>
						<div class="price">₹ 4250/year</div>
						<ul class="features">
							<li>
								<i class="fas fa-check-circle"></i> Master</li>
							<li><i class="fas fa-check-circle"></i> Goods Bookings</li>
							<li><i class="fas fa-check-circle"></i> Manifest Challan</li>
							<li><i class="fas fa-check-circle"></i> Lorry Hire/Way Bill</li>
							<li><i class="fas  fa-check-circle"></i> Lorry Arrival</li>
							<li><i class="fas fa-check-circle"></i> Godown Management</li>
							<li><i class="fas fa-times-circle"></i> Payments</li>
							<li><i class="fas fa-times-circle"></i> Billing</li>
							<li><i class="fas fa-check-circle"></i> Reports</li>
						</ul>
						<button>Buy Now</button>
					</div>
				</div>
			</div>


		</>
	)
}

export default Fullloadp