import {  React ,Component} from 'react';
import './css/Signup.css';
import post1 from '../images/post1.png';
// import axios from "axios";
// import { useNavigate } from "react-router-dom";

class Signup extends Component {
  state = {
    project: '',
    plan: '',
    name: '',
    mobile: '',
    email: '',
    password: '',
    description:'',
    emailStatus: ''
  };

  // handle input change
  handleChange = input => e => {
    this.setState({ [input]: e.target.value });
  }

  // handle form submission
  submitForm = (e) => {
    e.preventDefault();
    const {  project, plan, name,mobile,email,password,description } = this.state;
   

    // create a new XMLHttpRequest
    var xhr = new XMLHttpRequest();

    // listener to handle server response
    xhr.addEventListener('load', () => {
      this.setState({
        emailStatus: xhr.responseText
      });
    });
  
    // open the request with the verb and the url
    xhr.open('GET', `https://datacaresales.com/api/registration.php?sendto=${encodeURIComponent(email)}&name=${encodeURIComponent(name)}&plan=${encodeURIComponent(plan)}&project=${encodeURIComponent(project)}&mobile=${encodeURIComponent(mobile)}&password=${encodeURIComponent(password)}&description=${encodeURIComponent(description)}`);

    // send the request
    xhr.send();
    
    // reset form fields
    this.setState({
      
      project: '',
      plan: '',
      name: '',
      mobile: '',
      email: '',
      password: '',
      description:'',
      emailStatus: ''
    });
    alert('Are you sure your signup form submitted ?');
  }
  
  render() {
    const { project, plan, name,mobile,email,password,description, emailStatus } = this.state;
    return (
      <>
        <div class="content mt-5">
          <div class="container sign-shadow">
            <div class="row">
              <div class="col-md-6 col-sm-12 my-auto sign-img" >
                <img src={post1} alt="Image" class="img-fluid" />
              </div>
              <div class="col-md-6 col-sm-12 contents my-5">
                <div class="row justify-content-center">
                  <div class="col-md-8 border bg-light sign-shadow" >
                    <div class="my-3 mx-auto">
                      <h3 style={{ color: "#18ba60" }}>SIGN UP FORM</h3>
                    </div>
                    <form onSubmit={this.submitForm}>
                      <div class="mb-3 text-start d-flex justify-content-between align-items-center">
                        <label for="Projects" class="form-label">Projects You Need:-</label>
                        <select name="project" class="custom-select form-control" id="Projects" style={{ width: "250px", height: "32px" }} onChange={this.handleChange('project')} value={project} required autoFocus>
                          <option selected>Choose...</option>
                          <option value="Logistics S/W">Logistics S/W</option>
                          <option value="Full Load Transport S/W">Full Load Transport S/W</option>
                          <option value="Part Load Transport S/W">Part Load Transport S/W</option>
                          <option value="Manufacturing Management S/W">Manufacturing Management S/W</option>
                          <option value="Fleet Management S/W">Fleet Management S/W</option>
                          <option value="Fleet Maintenance S/W">Fleet Maintenance S/W</option>
                          <option value="School Management S/W">School Management S/W</option>
                          <option value="Payroll S/W">Payroll S/W</option>
                          <option value="Mobile App">M-obile App</option>
                        </select>
                      </div>
                      <div class="mb-3 text-start d-flex justify-content-between align-items-center">
                        <label for="plan" class="form-label">Plan Project:-</label>
                        <select name="plan" class="custom-select form-control" id="plan" style={{ width: "250px", height: "32px" }} onChange={this.handleChange('plan')} value={plan} required>
                          <option selected>Choose...</option>
                          <option value="basic">Basic Edition</option>
                          <option value="gold">Gold Edition</option>
                          <option value="platinum">Platinum Edition</option>
                        </select>
                      </div>
                      <div class="mb-3 text-start d-flex justify-content-between">
                        <label for="InputName" class="form-label">Username:-</label>
                        <input type="text" class="form-control" id="InputName" name="name" style={{ height: "30px", width: "260px" }} required onChange={this.handleChange('name')} value={name} />
                      </div>
                      <div class="mb-3 text-start d-flex justify-content-between">
                        <label for="MobileInput" class="form-label">Mobile No.:-</label>
                        <input type="text" maxlength="10" class="form-control" id="MobileInput" name="mobile" style={{ height: "30px", width: "260px" }} required onChange={this.handleChange('mobile')} value={mobile} />
                      </div>
                      <div class="mb-3 text-start d-flex justify-content-between">
                        <label for="InputEmail" class="form-label">Email address:-</label>
                        <input type="email" class="form-control" id="InputEmail" name="email" style={{ height: "30px", width: "260px" }} required onChange={this.handleChange('email')} value={email} />
                      </div>
                      <div class="mb-3 text-start d-flex justify-content-between">
                        <label for="InputPassword" class="form-label">Password:-</label>
                        <input type="password" class="form-control" id="InputPassword" name="password" style={{ height: "30px", width: "260px" }} required onChange={this.handleChange('password')} value={password} />
                      </div>

                      <div class="mb-3 text-start">
                        <label for="Textarea" class="form-label">Description:-</label>
                        <textarea name="description" class="form-control" id="Textarea" rows="3" style={{ maxHeight: "70px" }} onChange={this.handleChange('description')} value={description}></textarea >
                      </div>
                      <div class="mb-3 d-flex justify-content-between align-items-center">
                        <button class="btns btn" type="submit" >Register</button>

                        <span class="d-flex align-items-center">Already have an account? <a href="./Login" style={{ color: "#15a756", marginLeft: "4px" }}>Login</a></span>
                      </div>
                      {emailStatus && <h5 className='text-danger'>{emailStatus}</h5>}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
export default Signup;