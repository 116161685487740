import React from 'react';
// import './Logistics.css';
import ship from '../asset/ship.jpg';

// import logistics1 from '../asset/logistic1.jpg';
import logistics2 from '../asset/logistic2.jpg';
import Vproducts from './Vproducts';
import './Allproduct.css';
import { Link } from 'react-router-dom';

const Logistics = () => {
  return (
    <>

      <div class="mb-5">
        <div class="container">
          <div class="pt-5 pb-1">
            <h3 class="fw-bold text-center main-color" >LOGISTIC SOFTWARE</h3>
            <hr class="mb-5 main-color"  />
            <div class="row">
              <div class="col-lg-6 col-md-12 ps-2 mt-3">
                <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
                  <div class="carousel-inner border-green" >
                    <div class="carousel-item active">
                      <img src={ship} class="d-block w-100" alt="..." />
                    </div>
                    <div class="carousel-item">
                      <img src={logistics2} class="d-block w-100" alt="..." />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 mb-3 mt-3">
                <div class="fs-4 mb-3">
                  <span class="text-decoration">Logistic</span>
                </div>
                <ul class="font-size">
                  <li>Streamline logistics operations.</li>
                  <li>Real-time shipment tracking.</li>
                  <li>Route optimization.</li>
                  <li>Automated documentation.</li>
                  <li>Customizable workflows.</li>
                </ul>
                <p class="text text-view" >Our Logistic Software is a robust solution designed to streamline every aspect of your logistics operations.From order management to delivery tracking, it offers comprehensive tools to optimize efficiency and reduce costs.Features include real-time shipment tracking, route optimization, and automated documentation generation.With user-friendly interfaces and customizable workflows, our software adapts to your specific needs seamlessly.
                </p>
                <Link class="btn btn-success" type="button" to="/logisticsp"><i class="bi-cart-fill me-1"></i>View Price</Link>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="container">
        <h2 class="fw-bold fst-italic  text-center main-color" >FEATURES</h2>
        <hr class="border border-success" />
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 py-5">
          <div class="col d-flex flex-column align-items-center">
            <h1 class="ri-checkbox-circle-fill text-success"></h1>
            <div class="text-center">
              <h4 class="fw-bold mb-0">Goods Booking</h4>
              <p>Efficiently reserve and schedule transportation for your cargo.</p>
            </div>
          </div>
          <div class="col d-flex flex-column align-items-center">
            <h1 class="ri-checkbox-circle-fill text-success"></h1>
            <div class="text-center">
              <h4 class="fw-bold mb-0">Manifest Challan</h4>
              <p>Streamline documentation processes for accurate cargo tracking.</p>
            </div>
          </div>
          <div class="col d-flex flex-column align-items-center">
            <h1 class="ri-checkbox-circle-fill text-success"></h1>
            <div class="text-center">
              <h4 class="fw-bold mb-0">Godown Management</h4>
              <p>Optimize warehouse operations for seamless inventory handling.</p>
            </div>
          </div>
          <div class="col d-flex flex-column align-items-center">
            <h1 class="ri-checkbox-circle-fill text-success"></h1>
            <div class="text-center">
              <h4 class="fw-bold mb-0">Arrivals</h4>
              <p>Monitor and manage incoming shipments with precision.</p>
            </div>
          </div>
          <div class="col d-flex flex-column align-items-center">
            <h1 class="ri-checkbox-circle-fill text-success"></h1>
            <div class="text-center">
              <h4 class="fw-bold mb-0">Delivery</h4>
              <p>Ensure timely and secure delivery of goods to their destinations.</p>
            </div>
          </div>
          <div class="col d-flex flex-column align-items-center">
            <h1 class="ri-checkbox-circle-fill text-success"></h1>
            <div class="text-center">
              <h4 class="fw-bold mb-0">Payment Collection</h4>
              <p>Facilitate hassle-free payment transactions for services rendered.</p>
            </div>
          </div>
          <div class="col d-flex flex-column align-items-center">
            <h1 class="ri-checkbox-circle-fill text-success"></h1>
            <div class="text-center">
              <h4 class="fw-bold mb-0">Reports</h4>
              <p> Insights for decisions and <br /> evaluation.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="container mb-5">
      <h2 class="fw-bold fst-italic  text-center main-color" >OUR PRODUCTS</h2>
      <hr class="border border-success" />
      </div>
      <Vproducts/>
    </>
  )
}

export default Logistics