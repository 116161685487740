import React, { useState } from 'react';
import './css/floaticons.css';

  const Floaticons = () => {
    const [isOpen, setIsOpen] = useState(false);
  
    const togglebtn2 = () => {
      setIsOpen(!isOpen);
    };
  return (
    <div className="conse">
      <span className={`close-btn ${isOpen ? 'open' : ''}`} onClick={togglebtn2}>
        <i className="fa-solid fa-xmark"></i>
      </span>

      <div className={`media-icons ${isOpen ? 'open' : ''}`}>
        <a href="https://www.linkedin.com/company/datacare-consultancy-services-pvt-ltd/" target='_blank' style={{ background: '#0e76a8' }}>
          <i className="fa-brands fa-linkedin"></i>
          <span className="tooltip" style={{ color: '#0e76a8' }}>Linkedin</span>
        </a>
        <a href="https://www.youtube.com/@DatacareSales" target='_blank' style={{ background: '#ff0000' }}>
          <i className="fa-brands fa-youtube"></i>
          <span className="tooltip" style={{ color: '#ff0000' }}>YouTube</span>
        </a>
        <a href="https://www.facebook.com/people/Datacare-Consultancy-Services/61559689965671/" target='_blank' style={{ background: '#4267b2' }}>
          <i className="fa-brands fa-facebook-f"></i>
          <span className="tooltip" style={{ color: '#4267b2' }}>Facebook</span>
        </a>
        <a href="https://www.instagram.com/datacaresales/" target='_blank' style={{ background: '#e4405f' }}>
          <i className="fa-brands fa-instagram"></i>
          <span className="tooltip" style={{ color: '#e4405f' }}>Instagram</span>
        </a>
        <a href="/contact" target='_blank' style={{ background: '#4180ec' }}>
          <i className="fa-solid fa-message"></i>
          <span className="tooltip" style={{ color: '#e4405f' }}>Contact</span>
        </a>
      </div>
    </div>
  );
};

export default Floaticons;

