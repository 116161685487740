import React from 'react';
import './Vproducts.css';
import ship from '../asset/ship.jpg';
import transport3 from '../asset/transport3.jpg';
import transport2 from '../asset/transport2.jpg';
import manufacturing from '../asset/manufacturing.jpg';
import fleet from '../asset/fleet.jpg';
import fleetmain from '../asset/fleetmaintenance.jpg';
import schoolsw from '../asset/schoolsw.jpg';
import payroll from '../asset/payroll1.jpg';
import mobile from '../asset/mobile.jpg';

const Vproducts = () => {
	return (
		<>
			{/* <!-- <h4>Horizontal Flip</h4> --> */}

			<div className='cc container'>
				<div class="flip">
					<div class="front" style={{ backgroundImage: `url(${ship})` }}>
						<h5 class="text-shadow">LOGISTIC SOFTWARE</h5>
					</div>
					<div class="back">
						<h4>LOGISTIC SOFTWARE</h4>
						<p>Seamlessly manage logistics operations with our comprehensive softwares solution tailored for efficiency..</p>
						<a href="#" class="text-light">Read More..</a>
					</div>
				</div>
				<div class="flip">
					<div class="front" style={{ backgroundImage: `url(${transport3})` }}>
						<h5 class="text-shadow ">FULL LOAD TRANSPORT S/W</h5>
					</div>
					<div class="back">
						<h4>FULL LOAD TRANSPORT SOFTWARE</h4>
						<p>Optimize full load transportation logistics effortlessly with our specialized software solution..</p>
						<a href="#" class="text-light">Read More..</a>
					</div>
				</div>
				<div class="flip">
					<div class="front" style={{ backgroundImage: `url(${transport2})` }}>
						<h5 class="text-shadow">PART LOAD TRANSPORT S/W</h5 >
					</div>
					<div class="back">
						<h4>PART LOAD TRANSPORT SOFTWARE</h4>
						<p>Streamline part load transportation logistics efficiently with our dedicated software solution..</p>
						<a href="#" class="text-light ">Read More..</a>
					</div>
				</div>
				<div class="flip flip-vertical">
					<div class="front" style={{ backgroundImage: `url(${manufacturing})` }}>
						<h5 class="text-shadow">MANUFACTURING <br/>SOFTWARE</h5>
					</div>
					<div class="back">
						<h4>MANUFACTURING SOFTWARE</h4>
						<p>Streamline production processes with intuitive software tailored for manufacturers..</p>
						<a href="#" class="text-light">Read More..</a>
					</div>
				</div>
				<div class="flip">
					<div class="front" style={{ backgroundImage: `url(${fleet})` }}>
						<h5 class="text-shadow ">FLEET MANAGEMENT SOFTWARE</h5 >
					</div>
					<div class="back">
						<h4>FLEET MANAGEMENT SOFTWARE</h4>
						<p>Efficiently manage your fleet with ease using our streamlined software solution..</p>
						<a href="#" class="text-light">Read More..</a>
					</div>
				</div>
				<div class="flip">
					<div class="front" style={{ backgroundImage: `url(${fleetmain})` }}>
						<h5 class="text-shadow">FLEET MAINTENANCE SOFTWARE</h5 >
					</div>
					<div class="back">
						<h4>FLEET MAINTENANCE SOFTWARE</h4>
						<p>Keep your fleet in top condition, minimize downtime, and maximize performance with our maintenance software..<a href="#" class="text-light">Read More..</a></p>

					</div>
				</div>
				<div class="flip flip-vertical">
					<div class="front" style={{ backgroundImage: `url(${schoolsw})` }}>
						<h5 class="text-shadow">SCHOOL SOFTWARE</h5 >
					</div>
					<div class="back">
						<h4>SCHOOL SOFTWARE</h4>
						<p>Simplify administrative tasks, & elevate education management with our tailored software..</p>
						<a href="#" class="text-light">Read More..</a>
					</div>
				</div>
				<div class="flip">
					<div class="front" style={{ backgroundImage: `url(${payroll})` }}>
						<h5 class="text-shadow">PAYROLL SOFTWARE</h5 >
					</div>
					<div class="back">
						<h4>PAYROLL SOFTWARE</h4>
						<p>Automate payroll processes, ensure accuracy, and streamline financial management effortlessly..</p>
						<a href="#" class="text-light">Read More..</a>
					</div>
				</div>	
				<div class="flip flip-vertical">
					<div class="front" style={{ backgroundImage: `url(${mobile})` }}>
						<h5 class="text-shadow">MOBILE APPLICATION</h5 >
					</div>
					<div class="back">
						<h4>MOBILE APPLICATION</h4>
						<p>Access essential tools and information on-the-go with our user-friendly mobile application..</p>
						<a href="#" class="text-light">Read More..</a>
					</div>
				</div>
				
			</div>


		</>
	)
}

export default Vproducts