import React from 'react';
import './Allprice.css';

function Mobilep() {
	return (
		<>
			<div class="container mt-5 mb-5">
				<div class="round_div head-width">
					<div class="first p-3 fs-5 fw-bold active main-head mb-3 text-center"><span>MOBILE APP SOFTWARE PRICELIST</span></div>
				</div>
				<h2 class="text-center">India & South Asia (₹)</h2>
				<p class="text-center">India, Bangladesh, Sri Lanka, Nepal,</p>
			</div>
			<div class="container mb-5">

				<div class="pricing">
					<div class="plan">
						<h2>BASIC EDITION</h2>
						<div class="price">₹ 2150/year</div>

						<ul class="features">
							<li><i class="fas fa-check-circle"></i> 1 User Full Rights</li>
							<li><i class="fas fa-check-circle"></i>₹ 3000/- Per Extra User</li>
							<li><i class="fas fa-check-circle"></i> Maximum 2 Users </li>

						</ul>
						<button>Buy Now</button>
					</div>
					<div class="plan popular">
						<span>Most Popular</span>
						<h2>PLATINUM EDITION</h2>
						<div class="price">₹ 3500/year</div>
						<ul class="features">
							<li><i class="fas fa-check-circle"></i> 1 User Full Rights</li>
							<li><i class="fas fa-check-circle"></i> 1 User for View only</li>
							<li><i class="fas fa-check-circle"></i>  ₹ 3000/- per extra user </li>
						</ul>
						<button>Buy Now</button>
					</div>
					<div class="plan">
						<h2>GOLD EDITION</h2>
						<div class="price">₹ 4250/year</div>
						<ul class="features">
							<li><i class="fas fa-check-circle"></i>  Unlimited Users</li>

						</ul>
						<button>Buy Now</button>
					</div>
				</div>
			</div>
		</>
	)
}

export default Mobilep