import React from 'react';
import './Allprice.css';

function Fleetmaintenancep() {
	return (
		<>
			<div class="container mt-5 mb-5">
				<div class="round_div head-width ">
					<div class="first p-3 fs-5 fw-bold active main-head mb-3 text-center"><span>FLEET MAINTENANCE SOFTWARE PRICELIST</span></div>
				</div>
				<h2 class="text-center">India & South Asia (₹)</h2>
				<p class="text-center">India, Bangladesh, Sri Lanka, Nepal,</p>
			</div>
			<div class="container mb-5">

				<div class="pricing">
					<div class="plan">
						<h2>BASIC EDITION</h2>
						<div class="price">₹ 2150/year</div>
						<ul class="features">
							<li><i class="fas fa-check-circle"></i>  Vehicle Master</li>
							<li><i class="fas fa-check-circle"></i> Document Alert</li>
							<li><i class="fas fa-check-circle"></i>  Vendor Details</li>
							<li><i class="fas fa-check-circle"></i>   Tyre Management</li>
							<li><i class="fas fa-check-circle"></i>  Maintenance Job Card</li>
							<li><i class="fas fa-times-circle"></i>  Service Schedule</li>
							<li><i class="fas fa-times-circle"></i>  Parts Inventry</li>
							<li><i class="fas fa-times-circle"></i>  Parts Purchase</li>
							<li><i class="fas fa-times-circle"></i>  Vendor Ledger</li>
							<li><i class="fas fa-check-circle"></i>  Reports</li>
						</ul>
						<button>Buy Now</button>
					</div>
					<div class="plan popular">
						<span>Most Popular</span>
						<h2>PLATINUM EDITION</h2>
						<div class="price">₹ 3500/year</div>
						<ul class="features">
							<li><i class="fas fa-check-circle"></i>  Vehicle Master</li>
							<li><i class="fas fa-check-circle"></i> Document Alert</li>
							<li><i class="fas fa-check-circle"></i>  Vendor Details</li>
							<li><i class="fas fa-check-circle"></i>   Tyre Management</li>
							<li><i class="fas fa-check-circle"></i>  Maintenance Job Card</li>
							<li><i class="fas fa-check-circle"></i>  Service Schedule</li>
							<li><i class="fas fa-check-circle"></i>  Parts Inventry</li>
							<li><i class="fas fa-check-circle"></i>  Parts Purchase</li>
							<li><i class="fas fa-check-circle"></i>  Vendor Ledger</li>
							<li><i class="fas fa-check-circle"></i>  Reports</li>
						</ul>
						<button>Buy Now</button>
					</div>
					<div class="plan">
						<h2>GOLD EDITION</h2>
						<div class="price">₹ 4250/year</div>
						<ul class="features">
							<li><i class="fas fa-check-circle"></i>  Vehicle Master</li>
							<li><i class="fas fa-check-circle"></i> Document Alert</li>
							<li><i class="fas fa-check-circle"></i>  Vendor Details</li>
							<li><i class="fas fa-check-circle"></i>   Tyre Management</li>
							<li><i class="fas fa-check-circle"></i>  Maintenance Job Card</li>
							<li><i class="fas fa-check-circle"></i>  Service Schedule</li>
							<li><i class="fas fa-check-circle"></i>  Parts Inventry</li>
							<li><i class="fas fa-check-circle"></i>  Parts Purchase</li>
							<li><i class="fas fa-times-circle"></i>  Vendor Ledger</li>
							<li><i class="fas fa-check-circle"></i>  Reports</li>
						</ul>
						<button>Buy Now</button>
					</div>
				</div>
			</div>
		</>
	)
}

export default Fleetmaintenancep