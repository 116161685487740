import React from 'react';
import './css/policyfaqs.css';

const Policyfaqs = () => {
  return (
    <div class="container">
    <div class="row">
          <div class="col-lx-12">
                  <div class="card-body">
                    <div class="row justify-content-center mt-4">
                        <div class="col-xl-5 col-lg-8">
                            <div class="text-center">
                                <h3>Policy &amp; Faqs</h3>
                                <p class="text-muted">Discover all you need to know as our valued customer: Explore our clear policies and find quick answers to common questions, designed to make sure you have the information you need for a smooth experience.</p>
                                <div>
                                  <a href="./contact" class="btn btn-success me-2">Send us a Message</a>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    
                    <div class="row justify-content-center mt-5">
                        <div class="col-9">
                            <ul class="nav nav-tabs  nav-tabs-custom nav-justified justify-content-center faq-tab-box" id="pills-tab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="pills-genarel-tab" data-bs-toggle="pill" data-bs-target="#pills-genarel" type="button" role="tab" aria-controls="pills-genarel" aria-selected="true">
                                        <span class="font-size-16">FAQS</span>
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="pills-privacy_policy-tab" data-bs-toggle="pill" data-bs-target="#pills-privacy_policy" type="button" role="tab" aria-controls="pills-privacy_policy" aria-selected="false">
                                        <span class="font-size-16">Policy</span>
                                    </button>
                                  </li>
                                  <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="pills-teachers-tab" data-bs-toggle="pill" data-bs-target="#pills-pricing_plan" type="button" role="tab" aria-controls="pills-pricing_plan" aria-selected="false">
                                        
                                        <span class="font-size-16">General Questions</span>
                                    </button>
                                  </li>
                              </ul>
                        </div>
                        <div class="col-lg-9">
                            <div class="tab-content pt-3" id="pills-tabContent">
                                <div class="tab-pane fade active show" id="pills-genarel" role="tabpanel" aria-labelledby="pills-genarel-tab">
                                    <div class="row g-4 mt-2">
                                        <div class="col-lg-6">
                                            <h5>How can I request modifications to the software?</h5>
                                        <p class="lg-base">All modification requests should be made within the agreed-upon timeframe as specified in the contract. The Buyer is responsible for any delays in requesting modifications.</p>
                                        </div>
                                        <div class="col-lg-6">
                                            <h5>Who is responsible for providing hardware and networking solutions?</h5>
                                            <p class="lg-base">The Buyer is responsible for providing all necessary hardware and networking solutions required for the operation of the software.</p>
                                        </div>
                                        <div class="col-lg-6">
                                            <h5>Are there charges for additional requirements beyond the initial scope?</h5>
                                        <p class="lg-base">Yes, additional requirements beyond the initial scope will be chargeable based on their complexity and scope. Charges will be communicated and agreed upon before implementation.
                                        </p>
                                        </div>
                                        <div class="col-lg-6">
                                            <h5>What is your policy on refunds or adjustments?</h5>
                                            <p class="lg-base">We have a strict No Refund/Adjustment Policy once the software has been delivered and accepted by the Buyer, unless specified otherwise in the contract. </p>
                                        </div>
                                        <div class="col-lg-6">
                                            <h5>Is the software customizable to fit specific business needs?</h5>
                                            <p class="lg-base">Yes, our software is designed to be highly customizable to accommodate the specific requirements of each client.</p>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="tab-pane fade" id="pills-privacy_policy" role="tabpanel" aria-labelledby="pills-privacy_policy-tab">
                                    <div class="row g-4 mt-2">
                                        <div class="col-lg-6">
                                            <h5>Modifications Policy:</h5>
                                            <p class="lg-base">All modification requests should be submitted within the specified timeframe. The Buyer is responsible for any delays affecting project timelines.</p>
                                        </div>
                                        <div class="col-lg-6">
                                            <h5>Hardware and Networking:</h5>
                                            <p class="lg-base">The Buyer is responsible for providing all necessary hardware and networking solutions for the software.</p>
                                        </div>
                                        <div class="col-lg-6">
                                            <h5>Additional Requirements:</h5>
                                            <p class="lg-base">Any additional requirements beyond the initial scope will incur additional charges, agreed upon before implementation.</p>
                                        </div>
                                        <div class="col-lg-6">
                                            <h5>Refunds/Adjustments:</h5>
                                            <p class="lg-base">No refunds or adjustments are provided once the software has been delivered and accepted by the Buyer.</p>
                                        </div>
                                        <div class="col-lg-6">
                                            <h5>Customization:</h5>
                                            <p class="lg-base">The software is tailored to meet the unique needs of each customer.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="pills-pricing_plan" role="tabpanel">
                                    <div class="row g-4 mt-4">
                                        <div class="col-lg-6">
                                            <h5>What types of software solutions does your company provide?</h5>
                                        <p class="lg-base">We specialize in IT software development, offering customized solutions for various industries.</p>
                                        </div>
                                        <div class="col-lg-6">
                                            <h5>How do I request modifications or additional features for the software?</h5>
                                            <p class="lg-base">Requests for modifications or additional features should be directed through our designated channels. We'll discuss feasibility and provide timelines.</p>
                                        </div>
                                        <div class="col-lg-6">
                                            <h5>Does your company offer technical support after software implementation?</h5>
                                        <p class="lg-base">Yes, we provide ongoing technical support and maintenance services post-implementation.</p>
                                        </div>
                                        <div class="col-lg-6">
                                            <h5>How long does it take to develop and implement customized software?</h5>
                                            <p class="lg-base">Development timelines vary based on project complexity. We provide estimates during the consultation phase.</p>
                                        </div>
                                        <div class="col-lg-6">
                                            <h5>How can I initiate a partnership or engage your company for software development services?</h5>
                                            <p class="lg-base">To discuss software development needs or initiate a partnership, please contact our sales team via our website, email, or phone.</p>
                                        </div>
                                    </div>
                                </div>
                              </div>
                        </div>
                    </div>
                  </div>
              </div>
          </div>
      </div>

  )
}

export default Policyfaqs


