import React from 'react';
import Vproducts from './Vproducts';
// import logistics1 from '../asset/logistic1.jpg';
import schoolsw from '../asset/schoolsw.jpg';
import logistics2 from '../asset/logistic2.jpg';
import './Allproduct.css';
import { Link } from 'react-router-dom';

function Schoolmanagement() {
  return (
    <>
      <div class="mb-5">
        <div class="container">
          <div class="pt-5 pb-1">
            <h3 class="fw-bold text-center main-color" >SCHOOL MANAGEMENT SOFTWARE</h3>
            <hr class="mb-5 main-color" />
            <div class="row">
              <div class="col-lg-6 col-md-12 ps-2 mt-3">
                <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
                  <div class="carousel-inner border-green" >
                    <div class="carousel-item active">
                      <img src={schoolsw} class="d-block w-100" alt="..." />
                    </div>
                    <div class="carousel-item">
                      <img src={logistics2} class="d-block w-100" alt="..." />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 mb-3 mt-3">
                <div class="fs-4 mb-3">
                  <span class="text-decoration">School Management</span>
                </div>
                <ul class="font-size">
                  <li>Comprehensive school administration.</li>
                  <li>Attendance tracking.</li>
                  <li>Fee management.</li>
                  <li>Communication portals.</li>
                  <li>Parent engagement.</li>
                </ul>
                <p class="text text-view">Transform school administration with our comprehensive School Management Software.From student enrollment to exam management, it covers every aspect of school operations.Features include attendance tracking, fee management, and communication portals for seamless interaction between stakeholders.Enhance productivity, improve parent engagement, and streamline administrative tasks with our user-friendly platform.
                </p>
                <Link class="btn btn-success" type="button" to="/schoolmanagementp"><i class="bi-cart-fill me-1"></i>View Price</Link>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="container">
        <h2 class="fw-bold fst-italic  text-center main-color">FEATURES</h2>
        <hr class="border border-success" />
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 py-5">
          <div class="col d-flex flex-column align-items-center">
            <h1 class="ri-checkbox-circle-fill text-success"></h1>
            <div class="text-center">
              <h4 class="fw-bold mb-0">Addmission/Registeration</h4>
              <p>Unique Addmission Id, Photo and Registeration Form Upload Facility, Prev Education Details can be Recorded.</p>
            </div>
          </div>
          <div class="col d-flex flex-column align-items-center">
            <h1 class="ri-checkbox-circle-fill text-success"></h1>
            <div class="text-center">
              <h4 class="fw-bold mb-0">Report Card</h4>
              <p>Marks Entry, Marks Register, Scholatic Area, Scholatics Activity, Final Reports and Reports Cards.</p>
            </div>
          </div>
          <div class="col d-flex flex-column align-items-center">
            <h1 class="ri-checkbox-circle-fill text-success"></h1>
            <div class="text-center">
              <h4 class="fw-bold mb-0">Attendance Report</h4>
              <p>Enables you to manage attendance for teachers, students, and staff members without any error.</p>
            </div>
          </div>
          <div class="col d-flex flex-column align-items-center">
            <h1 class="ri-checkbox-circle-fill text-success"></h1>
            <div class="text-center">
              <h4 class="fw-bold mb-0">Fee Management</h4>
              <p>Manage and Pay Instant Fees, Transaction Facilities, Fee Defaulters and Stucture Available.</p>
            </div>
          </div>
          <div class="col d-flex flex-column align-items-center">
            <h1 class="ri-checkbox-circle-fill text-success"></h1>
            <div class="text-center">
              <h4 class="fw-bold mb-0">Exam Management</h4>
              <p>Create different types of exams based on grades, marks etc, Result view in CCE etc. Evaluation methods.</p>
            </div>
          </div>
          <div class="col d-flex flex-column align-items-center">
            <h1 class="ri-checkbox-circle-fill text-success"></h1>
            <div class="text-center">
              <h4 class="fw-bold mb-0">TimeTable Management</h4>
              <p>Dedicated app to teachers & students to easily check their timetables & their Attendance Charts and Reports.</p>
            </div>
          </div>
          <div class="col d-flex flex-column align-items-center">
            <h1 class="ri-checkbox-circle-fill text-success"></h1>
            <div class="text-center">
              <h4 class="fw-bold mb-0">Study Materials</h4>
              <p>Dedicated app to teachers & students to easily check their timetables & their Attendance Charts and Reports.</p>
            </div>
          </div>

        </div>
        <hr class="border border-success" />
      </div>
      <div class="container mb-5">
        <h2 class="fw-bold fst-italic  text-center main-color">OUR PRODUCTS</h2>
        <hr class="border border-success" />
      </div>
      <Vproducts />
    </>
  )
}

export default Schoolmanagement