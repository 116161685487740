import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Navbar from './components/Navbar';
import Home from './components/Home';
import Footer from './components/Footer';
import About from './components/About/About';
import Contact from './components/Contact';
import Login from './components/Login';
import Signup from './components/Signup';
import Logistics from './components/Products/Logistics';
import Fullload from './components/Products/Fullload';
import Partload from './components/Products/Partload';
import Manufacturing from './components/Products/Manufacturing';
import FLeetmanagement from './components/Products/FLeetmanagement';
import Fleetmaintenance from './components/Products/Fleetmaintenance';
import Schoolmanagement from './components/Products/Schoolmanagement';
import Payroll from './components/Products/Payroll';
import Mobile from './components/Products/Mobile';
import Logisticsp from './components/Pricing/Logisticsp';
import Fullloadp from './components/Pricing/Fullloadp';
import Partloadp from './components/Pricing/Partloadp';
import Manufacturingp from './components/Pricing/Manufacturingp';
import Fleetmaintenancep from './components/Pricing/Fleetmaintenancep';
import Fleetmanagementp from './components/Pricing/Fleetmanagementp';
import Schoolmanagementp from './components/Pricing/Schoolmanagementp';
import Payrollp from './components/Pricing/Payrollp';
import Mobilep from './components/Pricing/Mobilep';
import Policyfaqs from './components/policyfaqs'; 
import Floaticons from './components/Floaticons';
// import SignupForm from './Signupform';
// import Floaticons from './Floaticons';


function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='/home' element={<Home />}></Route>
          <Route path='/about' element={<About />}></Route>
          <Route path='/contact' element={<Contact />}></Route>
          <Route path='/login' element={<Login />}></Route>
          <Route path='/signup' element={<Signup />}></Route>
          <Route path='/logistics' element={<Logistics />}></Route>
          <Route path='/fullload' element={<Fullload />}></Route>
          <Route path='/partload' element={<Partload />}></Route>   
          <Route path='/manufacturing' element={<Manufacturing />}></Route>
          <Route path='/fleetmanagement' element={< FLeetmanagement/>}></Route>
          <Route path='/fleetmaintenance' element={<Fleetmaintenance />}></Route>  
          <Route path='/schoolmanagement' element={<Schoolmanagement />}></Route>
          <Route path='/payroll' element={< Payroll/>}></Route>
          <Route path='/mobile' element={<Mobile />}></Route>  
          <Route path='/logisticsp' element={<Logisticsp />}></Route>
          <Route path='/fullloadp' element={<Fullloadp />}></Route>
          <Route path='/partloadp' element={<Partloadp />}></Route>   
          <Route path='/manufacturingp' element={<Manufacturingp />}></Route>
          <Route path='/fleetmanagementp' element={< Fleetmanagementp/>}></Route>
          <Route path='/fleetmaintenancep' element={<Fleetmaintenancep />}></Route>  
          <Route path='/schoolmanagementp' element={<Schoolmanagementp />}></Route>
          <Route path='/payrollp' element={< Payrollp/>}></Route>
          <Route path='/mobilep' element={<Mobilep />}></Route> 
          <Route path='/policyfaqs' element={<Policyfaqs />}></Route> 
       

        </Routes>
        <Footer />
{/* <SignupForm/> */}
{/* <Floaticons/> */}
<Floaticons/>
      </BrowserRouter>

    </>
  );
}

export default App;
