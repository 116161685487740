import React from 'react';

import Vproducts from './Vproducts';
// import logistics1 from '../asset/logistic1.jpg';
import transport3 from '../asset/transport3.jpg';
import logistics2 from '../asset/logistic2.jpg';
import './Allproduct.css';
import { Link } from 'react-router-dom';


const Fulload = () => {
	return (
		<>
			<div class="mb-5">
				<div class="container">
					<div class="pt-5 pb-1">
						<h3 class="fw-bold text-center main-color">FULL LOAD TRANSPORT SOFTWARE</h3>
						<hr class="mb-5"  />
						<div class="row">
							<div class="col-lg-6 col-md-12 ps-2 mt-3">
								<div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
									<div class="carousel-inner border-green" >
										<div class="carousel-item active">
											<img src={transport3} class="d-block w-100" alt="..." />
										</div>
										<div class="carousel-item">
											<img src={logistics2} class="d-block w-100" alt="..." />
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-6 col-md-12 mb-3 mt-3">
								<div class="fs-4 mb-3">
									<span class="text-decoration">Full Load Transport</span>
								</div>
								<ul class="font-size">
									<li>End-to-end support for full load transportation.</li>
									<li>Load optimization.</li>
									<li>Freight rate management.</li>
									<li>Integrated financial tools.</li>
									<li>Increased transparency and control.</li>
								</ul>
								<p class="text text-view">Our Full Load Transport Software is tailored for companies managing full load transportation tasks.It provides end-to-end support, from order booking to invoice generation, simplifying complex processes.Features include load optimization, freight rate management, and integrated financial tools.Enjoy increased transparency and control over your transportation operations with our intuitive platform.
								</p>
								<Link class="btn btn-success" type="button"  to="/fullloadp"><i class="bi-cart-fill me-1"></i>View Price</Link>
							</div>
						</div>
					</div>
				</div>
			</div>


			<div class="container">
				<h2 class="fw-bold fst-italic  text-center mmain-color" >FEATURES</h2>
				<hr class="border border-success" />
				<div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 py-5">
					<div class="col d-flex flex-column align-items-center">
						<h1 class="ri-checkbox-circle-fill text-success"></h1>
						<div class="text-center">
							<h4 class="fw-bold mb-0">Goods Booking</h4>
							<p>Secure transportation arrangements for full-load shipments efficiently.</p>
						</div>
					</div>
					<div class="col d-flex flex-column align-items-center">
						<h1 class="ri-checkbox-circle-fill text-success"></h1>
						<div class="text-center">
							<h4 class="fw-bold mb-0">Lorry Hire/Challan</h4>
							<p>Streamlined trucking <br />arrangements.</p>
						</div>
					</div>
					<div class="col d-flex flex-column align-items-center">
						<h1 class="ri-checkbox-circle-fill text-success"></h1>
						<div class="text-center">
							<h4 class="fw-bold mb-0">Pending POD</h4>
							<p>Easily track and manage proof of delivery for outstanding shipments</p>
						</div>
					</div>
					<div class="col d-flex flex-column align-items-center">
						<h1 class="ri-checkbox-circle-fill text-success"></h1>
						<div class="text-center">
							<h4 class="fw-bold mb-0">Broker Settlement</h4>
							<p>Simplify settlement processes for brokerage services rendered.</p>
						</div>
					</div>
					<div class="col d-flex flex-column align-items-center">
						<h1 class="ri-checkbox-circle-fill text-success"></h1>
						<div class="text-center">
							<h4 class="fw-bold mb-0">Party Billing</h4>
							<p>Facilitate accurate and timely invoicing for clients.</p>
						</div>
					</div>
					<div class="col d-flex flex-column align-items-center">
						<h1 class="ri-checkbox-circle-fill text-success"></h1>
						<div class="text-center">
							<h4 class="fw-bold mb-0">Payment Collection</h4>
							<p>Manage client payments <br />effectively.</p>
						</div>
					</div>
					<div class="col d-flex flex-column align-items-center">
						<h1 class="ri-checkbox-circle-fill text-success"></h1>
						<div class="text-center">
							<h4 class="fw-bold mb-0">Reports</h4>
							<p>Access analytics for informed decisions.</p>
						</div>
					</div>
				</div>
			</div>
			<div class="container mb-5">
				<h2 class="fw-bold fst-italic  text-center main-color">OUR PRODUCTS</h2>
				<hr class="border border-success" />
			</div>
			<Vproducts />

		</>
	)
}

export default Fulload