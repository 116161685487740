// import React from 'react'
import React, { Component } from 'react';
import { Link } from "react-router-dom";


class Footer extends Component {
    state = {

        email: '',
        emailStatus: ''
    };

    // handle input change
    handleChange = input => e => {
        this.setState({ [input]: e.target.value });

    }

    // handle form submission
    submitForm = (e) => {
        e.preventDefault();
        const {  email } = this.state;

        // create a new XMLHttpRequest
        var xhr = new XMLHttpRequest();

        // listener to handle server response
        xhr.addEventListener('load', () => {
            this.setState({
                emailStatus: xhr.responseText
            });
        });

        // open the request with the verb and the url
        xhr.open('GET', `https://datacaresales.com/api/regupdate.php?sendto=${encodeURIComponent(email)}`);

        // send the request
        xhr.send();

        // reset form fields
        this.setState({         
            email: ''
        });
    }

    render() {
        const { email,emailStatus } = this.state;
        return (
            <>
                <div className="Footer">
                    <div className="container-fluid p-5">
                        <div className="row">
                            <div className="col-md-6 col-lg-5 col-12 ft-1">
                                <h3><span class="text-success">Datacare</span>Consultancy Services</h3>
                                <p>Simplified software solutions for modern businesses! 💡 Let's upgrade your digital presence with DataCare Consultancy Services Pvt. Ltd. (DCSPL).</p>
                                <div className="footer-icons">.
                                    <a href='https://www.facebook.com/people/Datacare-Consultancy-Services/61559689965671/' target='_blank'><i class="fa-brands fa-facebook"></i></a>
                                    <a href='https://www.youtube.com/@DatacareSales' target='_blank'><i class="fa-brands fa-youtube"></i></a>
                                    <a href='https://www.instagram.com/datacaresales/' target='_blank'><i class="fa-brands fa-instagram"></i></a>
                                    <a href='https://www.linkedin.com/company/datacare-consultancy-services-pvt-ltd/' target='_blank'><i class="fa-brands fa-linkedin-in"></i></a>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 col-12 ft-2">
                                <h5 class="text-success">Quick Links</h5>
                                <ul>
                                    <li className="nav-item">           
                                        <Link className="" to="/about">About Us</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="" to="/contact">Contact Us</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="" to="/policyfaqs">Policy</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="" to="/policyfaqs">FAQs</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-6 col-lg-4 col-12 ft-3">
                                <h5 class="text-success">Contact</h5>
                                <p><i className="fa-solid fa-phone-volume"></i> (0120) 4124140 <br />+91-8010020036</p>
                                <p><i className="fa-solid fa-envelope"></i>sales@datacaresales.com</p>
                                <p><i className="fa-solid fa-paper-plane"></i>DataCare Consultancy Services,<br /> B-4 Sector-63 Noida 201309,India</p>
                            </div>
                            <div className="news col-lg-3 col-md-6" >
                                <form onSubmit={this.submitForm}>
                                <h4 className="text-success mb-3">Newsletter</h4>
                                <p>Get Regularly Updates and Offers.</p>
                                <div class="position-relative mx-auto shadow" >
                                    <input className="form-control border-0 w-100 py-3 ps-4 pe-5" type="email" onChange={this.handleChange('email')} value={email} placeholder="Your email" id="email" name="email" />
                                    {emailStatus && <h5 className='text-primary'>{emailStatus}</h5>}
                                    <button type="submit" name="mail" className="btn text-white btn-success py-2 position-absolute top-0 end-0 mt-2 me-2">SignUp</button>
                                </div>
                                </form>
                            </div>
                        </div>
                    </div>


                    <div className='Last-footer bg-success'>
                        <p>© Datacare, All Right Reserved.</p>
                    </div>
                </div>
            </>
        )
    }
}

export default Footer
