import React from 'react';
import Slider from './Slider';
import Services from './Services';
import Testimonials from './Testimonials';
// import log from './asset/logo.png';
import './css/Home.css';
import Vproducts from './Products/Vproducts';

export const Home = () => {
	return (
		<>
		<Slider />
			
		<div className='mt-5'>
			
			<Services />	
			
			<div class="container ">
				<h2 class="fw-bold  text-center  mt-5">OUR PRODUCTS</h2>
		
			</div>
			<Vproducts/>
			<Testimonials />
			</div>
		</>
	)
}
export default Home