import React from 'react'
import './Allprice.css';
function Manufacturingp() {
	return (
		<>
			<div class="container mt-5 mb-5">
				<div class="round_div head-width ">
					<div class="first p-3 fs-5 fw-bold active main-head mb-3 text-center"><span>MANUFACTURING MANAGEMENT SOFTWARE PRICELIST</span></div>
				</div>
				<h2 class="text-center">India & South Asia (₹)</h2>
				<p class="text-center">India, Bangladesh, Sri Lanka, Nepal,</p>
			</div>
			<div class="container mb-5">

				<div class="pricing">
					<div class="plan">
						<h2>BASIC EDITION</h2>
						<div class="price">₹ 2150/year</div>
						<ul class="features">
							<li><i class="fas fa-check-circle"></i> Master</li>
							<li><i class="fas fa-check-circle"></i> Production</li>
							<li><i class="fas fa-check-circle"></i> Shipping Planning</li>
							<li><i class="fas fa-times-circle"></i> Invoice & Order</li>
							<li><i class="fas fa-times-circle"></i> Purchasing Order</li>
							<li><i class="fas fa-times-circle"></i> Payroll</li>
							<li><i class="fas fa-check-circle"></i> Account</li>

						</ul>
						<button>Buy Now</button>
					</div>
					<div class="plan popular">
						<span className='px-2'>Most Popular</span>
						<h2>PLATINUM EDITION</h2>
						<div class="price">₹ 3500/year</div>
						<ul class="features">
							<li><i class="fas fa-check-circle"></i> Master</li>
							<li><i class="fas fa-check-circle"></i> Production</li>
							<li><i class="fas fa-check-circle"></i> Shipping Planning</li>
							<li><i class="fas fa-check-circle"></i> Invoice & Order</li>
							<li><i class="fas fa-check-circle"></i> Purchasing Order</li>
							<li><i class="fas fa-check-circle"></i> Payroll</li>
							<li><i class="fas fa-check-circle"></i> Account</li>
						</ul>
						<button>Buy Now</button>
					</div>
					<div class="plan">
						<h2>GOLD EDITION</h2>
						<div class="price">₹ 4250/year</div>
						<ul class="features">
							<li><i class="fas fa-check-circle"></i> Master</li>
							<li><i class="fas fa-check-circle"></i> Production</li>
							<li><i class="fas fa-check-circle"></i> Shipping Planning</li>
							<li><i class="fas fa-check-circle"></i> Invoice & Order</li>
							<li><i class="fas fa-check-circle"></i> Purchasing Order</li>
							<li><i class="fas fa-times-circle"></i> Payroll</li>
							<li><i class="fas fa-check-circle"></i> Account</li>
						</ul>
						<button>Buy Now</button>
					</div>
				</div>
			</div>
		</>
	)
}

export default Manufacturingp