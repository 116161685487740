import React from 'react';
import './css/Testimonials.css';
import Airtheme from './Airtheme';
import user from './asset/user.png';

const Testimonials = () => {
	return (
		<>
		
			<div className='mt-5'>

				<div className="outerdiv">
					<h4 className='mt-5'>Testimonials</h4>
					<h1>What Clients Say</h1>
					<span className='fs-6'>We place huge value on strong relationships and have seen the benefit they bring to our business. Customer feedback is vital in helping us to get it right.</span>
					<div className="innerdiv">
						<div className="div1 eachdiv">
							<div className="userdetails">
								<div className="imgbox">
									<img src={user} alt="" />
								</div>
								<div className="detbox">
									<p className="name">Rajesh Sharma</p>
									<p className="designation">Operations Manager</p>
								</div>
							</div>
							<div className="review">
								<h4>The team's guidance and encouragement were invaluable, making it a truly life-changing journey.</h4>
								<p>“ DataCare Services truly understood our logistics challenges and provided a fleet management software that exceeded our expectations. Their solution for part-load and full-load transport has streamlined our operations and boosted our efficiency. We're grateful for their expertise and dedication ”</p>
							</div>
						</div>
						<div className="div2 eachdiv">
							<div className="userdetails">
								<div className="imgbox">
									<img src={user} alt="" />
								</div>
								<div className="detbox">
									<p className="name">Neha Patel</p>
									<p className="designation">Principal</p>
								</div>
							</div>
							<div className="review">
								<h4>The team was very supportive and kept me motivated</h4>
								<p>“ DataCare's school management software streamlined our tasks, letting us focus on students. Grateful for their partnership! ”</p>
							</div>
						</div>
						<div className="div3 eachdiv">
							<div className="userdetails">
								<div className="imgbox">
									<img src={user} alt="" />
								</div>
								<div className="detbox">
									<p className="name dark">Aryan Singh</p>
									<p className="designation dark">Operations Manager</p>
								</div>
							</div>
							<div className="review dark">
								<h4>Such a life-changing experience. Highly recommended!</h4>
								<p>“ We've relied on DataCare Services' fleet maintenance software for years, and it's been a lifesaver for our manufacturing business. Their attention to detail and commitment to understanding our industry's unique needs have made all the difference. Thanks to their ongoing support, our fleet stays in peak condition, and our operations run smoothly. ”</p>
							</div>
						</div>
						<div className="div4 eachdiv">
							<div className="userdetails">
								<div className="imgbox">
									<img src={user} alt="" />
								</div>
								<div className="detbox">
									<p className="name dark">Priya Mishra</p>
									<p className="designation dark">Verified Retailer</p>
								</div>
							</div>
							<div className="review dark">
								<h4>An overall wonderful and rewarding experience</h4>
								<p>“ DataCare's retail software streamlines store management from inventory to sales. Tailored solutions and a pleasure to work with! ”</p>
							</div>
						</div>

						<div className="div5 eachdiv">
							<div className="userdetails">
								<div className="imgbox">
									<img src={user} alt="" />
								</div>
								<div className="detbox">
									<p className="name">Rohan Gupta</p>
									<p className="designation">Warehouse Manager</p>
								</div>
							</div>
							<div className="review">
								<h4>Life before this experience was a blur of routine and monotony. But after diving into this journey, I found clarity and purpose like never before.</h4>
								<p>“ DataCare Services' retail software has been instrumental in helping us manage our store efficiently. From inventory tracking to point-of-sale solutions, their software covers all the bases. We appreciate their dedication to providing tailored solutions for retailers like us. Working with them has been a pleasure!”</p>
							</div>
						</div>
					</div>
				</div>
			
				{/* <div className="attribution">
					Challenge by <a href="" target="_blank">Frontend Mentor</a><br />
					Coded by <a href="" target="_blank">Raja</a>.
				</div> */}
					<Airtheme/>
			</div>
		
		</>
	)
}

export default Testimonials


