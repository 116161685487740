import React from 'react';
import './About.css';
import 'remixicon/fonts/remixicon.css';
import about_us from '../asset/about_us.jpg';
import instructor from '../asset/instructor.jpg'
import more1 from '../asset/more1.jpg';
import more2 from '../asset/more2.jpg';
import more3 from '../asset/more3.jpg';
import more4 from '../asset/more4.jpg';
import manicon from '../asset/manicon.png';
import Floaticons from '../Floaticons';



const About = () => {
    return (
        <>
            <div class="container-xxl py-5">
                <div class="container">
                    <div class="row g-5">
                        <div class="col-lg-6" style={{ minHeight: "400px" }}>
                            <div class="position-relative h-100">
                                <img class="img-fluid position-absolute w-100 h-100 border border-success" src={about_us} style={{ objectFit: "cover" }} alt=""/>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <h6 class="section-title text-start text-success pe-3">About Us</h6>
                            <h1 class="mb-4">Welcome to DATACARE</h1>
                            <p class="mb-4">DATACARE is a software development house with proven expertise in systems and application developments, which is used to offer solutions across vertical segments, Software Development Consulting, Web Architecture & IT Infrastructure Management.</p>
                            <p class="mb-4">DATACARE believes in quality software development by implementing a comprehensive quality policy to deliver results on time and within budget. It is a software development house with strong management and software development processes and state-of-art infrastructure.</p>
                            <p class="mb-2">Our expertise in the field of</p>
                            <div class="row gy-2 gx-4 mb-4">
                                <div class="col-sm-6">
                                    <p class="mb-0"><i class="fa fa-arrow-right text-success me-2"></i>A Complete Software Solutions</p>
                                </div>
                                <div class="col-sm-6">
                                    <p class="mb-0"><i class="fa fa-arrow-right text-success me-2"></i>Office Automation Software</p>
                                </div>
                                <div class="col-sm-6">
                                    <p class="mb-0"><i class="fa fa-arrow-right text-success me-2"></i>Designing and Developing</p>
                                </div>
                                <div class="col-sm-6">
                                    <p class="mb-0"><i class="fa fa-arrow-right text-success me-2"></i>Management Software</p>
                                </div>
                                <div class="col-sm-6">
                                    <p class="mb-0"><i class="fa fa-arrow-right text-success me-2"></i>Systems & Application Developments</p>
                                </div>
                                <div class="col-sm-6">
                                    <p class="mb-0"><i class="fa fa-arrow-right text-success me-2"></i>Transport, Accounting, Inventory System</p>
                                </div>
                            </div>
                            <a class="btn btn-success py-3 px-4 mt-2" href="">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="py-5">
                <div class="container">
                    <div class="row justify-content-center text-center mb-3 mb-md-5">
                        <div class="col-lg-8 col-xxl-7">
                            <span class="text-success">Showcase</span>
                            <h6 class="display-6 fw-bold">Our Expertise</h6>
                        </div>
                    </div>
                    <div class="carousel slide container pointer-event" data-bs-ride="carousel" id="galleryCarouse1">
                        <div class="carousel-inner">
                            <div class="caro1 carousel-item active">
                                <div class="row gy-4">
                                    <div class="col-md-3"><img alt="" class="img-fluid rounded-1" src={more1}/></div>
                                    <div class="col-md-3"><img alt="" class="img-fluid rounded-1" src={more2}/></div>
                                    <div class="col-md-3"><img alt="" class="img-fluid rounded-1" src={more3}/></div>
                                    <div class="col-md-3"><img alt="" class="img-fluid rounded-1" src={more4}/></div>
                                </div>
                            </div>
                            <div class="caro1 carousel-item">
                                <div class="row gy-4">
                                    <div class="col-md-3"><img alt="" class="img-fluid rounded-1" src={more1}/></div>
                                    <div class="col-md-3"><img alt="" class="img-fluid rounded-1" src={more2}/></div>
                                    <div class="col-md-3"><img alt="" class="img-fluid rounded-1" src={more3}/></div>
                                    <div class="col-md-3"><img alt="" class="img-fluid rounded-1" src={more4}/></div>
                                </div>
                            </div>
                        </div><button class="carousel-control-prev" data-bs-slide="prev" data-bs-target="#galleryCarouse1" type="button"><span aria-hidden="true" class="carousel-control-prev-icon"></span> <span class="visually-hidden">Previous</span></button> <button class="carousel-control-next" data-bs-slide="next" data-bs-target="#galleryCarouse1" type="button"><span aria-hidden="true" class="carousel-control-next-icon"></span> <span class="visually-hidden">Next</span></button>
                    </div>
                </div>
            </div>

            <div class="container-xxl py-5">
                <div class="container">
                    <div class="text-center">
                        <h6 class="section-title text-center text-success px-3">Instructors</h6>
                        <h1 class="mb-5">Our Teams</h1>
                    </div>
                    <div class="row g-4">
                        <div class="col-lg-3 col-md-6 ">
                            <div class="team-item bg-light">
                                <div class="overflow-hidden">
                                    <img class="img-fluid" src={manicon} alt=""/>
                                </div>
                                {/* <div class="position-relative d-flex justify-content-center" style={{ marginTop: "-23px" }}>
                                    <div class="bg-light d-flex justify-content-center pt-2 px-1">
                                        <a class="btn btn-sm-square btn-success mx-1" href=""><i class="fab fa-facebook-f"></i></a>
                                        <a class="btn btn-sm-square btn-success mx-1" href=""><i class="fab fa-twitter"></i></a>
                                        <a class="btn btn-sm-square btn-success mx-1" href=""><i class="fab fa-instagram"></i></a>
                                    </div>
                                </div> */}
                                <div class="text-center p-4">
                                    <h5 class="mb-0">Prem Singh</h5>
                                    <small class="smalln">CEO</small>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team-item bg-light">
                                <div class="overflow-hidden">
                                    <img class="img-fluid" src={manicon} alt=""/>
                                </div>
                                
                                <div class="text-center p-4">
                                    <h5 class="mb-0">Kartik</h5>
                                    <small class="smalln">App Developer</small>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team-item bg-light">
                                <div class="overflow-hidden">
                                    <img class="img-fluid" src={manicon} alt=""/>
                                </div>
                                
                                <div class="text-center p-4">
                                    <h5 class="mb-0">Varun Gautam</h5>
                                    <small class="smalln">Web Developer</small>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team-item bg-light">
                                <div class="overflow-hidden">
                                    <img class="img-fluid" src={manicon} alt=""/>
                                </div>
                                
                                <div class="text-center p-4">
                                    <h5 class="mb-0">Rajaram Sah</h5>
                                    <small class="smalln">Web Developer</small>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team-item bg-light">
                                <div class="overflow-hidden">
                                    <img class="img-fluid" src={manicon} alt=""/>
                                </div>
                                
                                <div class="text-center p-4">
                                    <h5 class="mb-0">Shiavam</h5>
                                    <small class="smalln">Sales & Marketing</small>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team-item bg-light">
                                <div class="overflow-hidden">
                                    <img class="img-fluid" src={manicon} alt=""/>
                                </div>
                                
                                <div class="text-center p-4">
                                    <h5 class="mb-0">Deepak</h5>
                                    <small class="smalln">Accounts</small>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team-item bg-light">
                                <div class="overflow-hidden">
                                    <img class="img-fluid" src={manicon} alt=""/>
                                </div>
                                
                                <div class="text-center p-4">
                                    <h5 class="mb-0">Manoj</h5>
                                    <small class="smalln">Tech Support</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Floaticons />
        </>
    )
}

export default About
