import React from 'react'
import { Link } from "react-router-dom";
import logo from '../images/datacarelogo.png';

const Navbar = () => {
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
                <div className="container-fluid mx-5 " >
                    <Link className="navbar-brand" to="/"><img src={logo} alt="Datacare"/></Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    {/* means */}
                    <div className="collapse navbar-collapse align-middle" id="navbarNav">
                        <ul className="nav_ul navbar-nav ms-auto align-items-center hover">
                            <li className="nav-item">
                                <Link className="nav-link" to="/home">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/about">About Us</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Products
                                </a>
                                <ul className="dropdown-menu " aria-labelledby="navbarDropdown">
                                    <Link className="dropdown-item" to="/logistics">Logistics S/W</Link>
                                    <Link className="dropdown-item" to="/fullload">Full Load Transport S/W</Link>
                                    <Link className="dropdown-item" to="/partload">Part Load Transport S/W</Link>
                                    <Link className="dropdown-item" to="/manufacturing">Manufacturing Management S/W</Link>
                                    <Link className="dropdown-item" to="/fleetmanagement">Fleet Management S/W</Link>
                                    <Link className="dropdown-item" to="/fleetmaintenance">Fleet Maintenance S/W</Link>
                                    <Link className="dropdown-item" to="/schoolmanagement">School Management S/W</Link>
                                    <Link className="dropdown-item" to="/payroll">Payroll S/W</Link>
                                    <Link className="dropdown-item" to="/mobile">Mobile Application S/W</Link>
                                    
                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Pricing
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <Link className="dropdown-item" to="/logisticsp">Logistics S/W</Link>
                                    <Link className="dropdown-item" to="/fullloadp">Full Load Transport S/W</Link>
                                    <Link className="dropdown-item" to="/partloadp">Part Load Transport S/W</Link>
                                    <Link className="dropdown-item" to="/manufacturingp">Manufacturing Management S/W</Link>
                                    <Link className="dropdown-item" to="/fleetmanagementp">Fleet Management S/W</Link>
                                    <Link className="dropdown-item" to="/fleetmaintenancep">Fleet Maintenance S/W</Link>
                                    <Link className="dropdown-item" to="/schoolmanagementp">School Management S/W</Link>
                                    <Link className="dropdown-item" to="/payrollp">Payroll S/W</Link>
                                    <Link className="dropdown-item" to="/mobilep">Mobile Application S/W</Link>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/contact">Contact Us</Link>
                            </li>
                            <div className="form-check form-switch">
                                <p className="my-auto">Call Us | +91 120 4124140</p>
                            </div>
                            <div className="mx-3">
                                <Link to="/login"><button type="button" className="btn btnl btn-outline-success mx-2">Login</button></Link>
                                <Link to="/signup"><button type="button" className="btn btnl btn-outline-success  mx-2">Sign Up</button></Link>
                            </div>
                        </ul>
                    </div>
                    {/* end */}
                </div>
            </nav>
        </>
    )
}

export default Navbar
