import React from 'react'
import Vproducts from './Vproducts';
// import logistics1 from '../asset/logistic1.jpg';
import payroll from '../asset/payroll1.jpg';
import logistics2 from '../asset/logistic2.jpg';
import './Allproduct.css';
import { Link } from 'react-router-dom';


function Payroll() {
  return (
<>
<div class="mb-5">
  <div class="container">
    <div class="pt-5 pb-1">
      <h3 class="fw-bold text-center main-color" >PAYROLL SOFTWARE</h3>
      <hr class="mb-5 main-color"/>
      <div class="row">
        <div class="col-lg-6 col-md-12 ps-2 mt-3">
          <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner border-green">
              <div class="carousel-item active">
              <img src={payroll} class="d-block w-100" alt="..." />
              </div>
              <div class="carousel-item">
              <img src={logistics2} class="d-block w-100" alt="..." />
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 mb-3 mt-3">
          <div class="fs-4 mb-3">
            <span class="text-decoration">Payroll</span>
          </div>
          <ul class="font-size">
            <li>Simplify payroll processing.</li>
            <li>Automated calculations.</li>
            <li>Tax filing integration.</li>
            <li>Employee self-service portals.</li>
            <li>Compliance and accuracy.</li>
          </ul>
          <p class="text text-view">Simplify payroll processing with our intuitive Payroll Software.Automate calculations, manage deductions, and generate compliant payroll reports effortlessly.Features include tax filing integration, employee self-service portals, and customizable payroll templates.Ensure accuracy and compliance while saving time and resources with our reliable payroll solution.
          </p>
          <Link class="btn btn-success" type="button" to="/payrollp"><i class="bi-cart-fill me-1"></i>View Price</Link>
        </div>
      </div>
    </div>
    </div>
</div>


<div class="container">
  <h2 class="fw-bold fst-italic  text-center main-color" >FEATURES</h2>
  <hr class="border border-success"/>
  <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 py-5">
    <div class="col d-flex flex-column align-items-center">
      <h1 class="ri-checkbox-circle-fill text-success"></h1>
      <div class="text-center">
        <h4 class="fw-bold mb-0">Doc Management</h4>
        <p>Effortlessly organize and access essential documents.</p>
      </div>
    </div>
    <div class="col d-flex flex-column align-items-center">
      <h1 class="ri-checkbox-circle-fill text-success"></h1>
      <div class="text-center">
        <h4 class="fw-bold mb-0">Approvals & Reports</h4>
        <p>Easily manage approvals and generate insightful reports.</p>
      </div>
    </div>
    <div class="col d-flex flex-column align-items-center">
      <h1 class="ri-checkbox-circle-fill text-success"></h1>
      <div class="text-center">
        <h4 class="fw-bold mb-0">Loans & Advance</h4>
        <p>Simplify loan and advance management for your employees.</p>
      </div>
    </div>
    <div class="col d-flex flex-column align-items-center">
      <h1 class="ri-checkbox-circle-fill text-success"></h1>
      <div class="text-center">
        <h4 class="fw-bold mb-0">Auto Calculate Salary</h4>
        <p>Ensure accurate and efficient salary calculations every time.</p>
      </div>
    </div>
    <div class="col d-flex flex-column align-items-center">
      <h1 class="ri-checkbox-circle-fill text-success"></h1>
      <div class="text-center">
        <h4 class="fw-bold mb-0">Manage Attendance</h4>
        <p>Seamlessly track and manage employee attendance.</p>
      </div>
    </div>
    <div class="col d-flex flex-column align-items-center">
      <h1 class="ri-checkbox-circle-fill text-success"></h1>
      <div class="text-center">
        <h4 class="fw-bold mb-0">PF & ESI Register</h4>
        <p>Stay compliant with seamless management of PF and ESI records.</p>
      </div>
    </div>
    <div class="col d-flex flex-column align-items-center">
      <h1 class="ri-checkbox-circle-fill text-success"></h1>
      <div class="text-center">
        <h4 class="fw-bold mb-0">Biometric Integration</h4>
        <p> Enhance security and accuracy with biometric integration.</p>
      </div>
    </div>
    <div class="col d-flex flex-column align-items-center">
      <h1 class="ri-checkbox-circle-fill text-success"></h1>
      <div class="text-center">
        <h4 class="fw-bold mb-0">Operation Management</h4>
        <p>Optimize your HR operations for increased productivity.</p>
      </div>
    </div>
  </div>
  <hr class="border border-success"/>
</div>
<div class="container mb-5">
				<h2 class="fw-bold fst-italic  text-center main-color">OUR PRODUCTS</h2>
				<hr class="border border-success" />
			</div>
			<Vproducts />
</>
  )
}

export default Payroll