import React from 'react'
import Vproducts from './Vproducts';
// import logistics1 from '../asset/logistic1.jpg';
import fleet from '../asset/fleet.jpg';
import logistics2 from '../asset/logistic2.jpg';
import './Allproduct.css';
import { Link } from 'react-router-dom';
function FLeetmanagement() {
	return (
		<>
			<div class="mb-5">
				<div class="container">
					<div class="pt-5 pb-1">
						<h3 class="fw-bold text-center main-color">FLEET MANAGEMENT SOFTWARE</h3>
						<hr class="mb-5 main-color"  />
						<div class="row">
							<div class="col-lg-6 col-md-12 ps-2 mt-3">
								<div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
									<div class="carousel-inner border-green" >
										<div class="carousel-item active">
											<img src={fleet} class="d-block w-100" alt="..." />
										</div>
										<div class="carousel-item">
											<img src={logistics2} class="d-block w-100" alt="..." />
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-6 col-md-12 mb-3 mt-3">
								<div class="fs-4 mb-3">
									<span class="text-decoration">Fleet Management</span>
								</div>
								<ul class="font-size">
									<li>Comprehensive fleet management.</li>
									<li>Vehicle tracking.</li>
									<li>Driver behavior monitoring.</li>
									<li>Maintenance scheduling.</li>
									<li>Fuel management.</li>
								</ul>
								<p class="text text-view" >Take control of your fleet operations with our comprehensive Fleet Management Software.Track vehicle location, monitor driver behavior, and optimize routes for maximum efficiency.Features include maintenance scheduling, fuel management, and compliance tracking to ensure regulatory adherence.Increase fleet productivity and reduce operational costs with our advanced fleet management solution.
								</p>
								<Link class="btn btn-success" type="button" to="/fleetmanagement"><i class="bi-cart-fill me-1"></i>View Price</Link>
							</div>
						</div>
					</div>
				</div>
			</div>


			<div class="container">
				<h2 class="fw-bold fst-italic  text-center main-color" >FEATURES</h2>
				<hr class="border border-success" />
				<div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 py-5">
					<div class="col d-flex flex-column align-items-center">
						<h1 class="ri-checkbox-circle-fill text-success"></h1>
						<div class="text-center">
							<h4 class="fw-bold mb-0">Vehicle Management</h4>
							<p>Efficiently monitor and maintain your fleet of vehicles.</p>
						</div>
					</div>
					<div class="col d-flex flex-column align-items-center">
						<h1 class="ri-checkbox-circle-fill text-success"></h1>
						<div class="text-center">
							<h4 class="fw-bold mb-0">Driver Setup</h4>
							<p>Streamline driver management and assignment processes.</p>
						</div>
					</div>
					<div class="col d-flex flex-column align-items-center">
						<h1 class="ri-checkbox-circle-fill text-success"></h1>
						<div class="text-center">
							<h4 class="fw-bold mb-0">Loading Slip</h4>
							<p>Simplify documentation for cargo loading activities.</p>
						</div>
					</div>
					<div class="col d-flex flex-column align-items-center">
						<h1 class="ri-checkbox-circle-fill text-success"></h1>
						<div class="text-center">
							<h4 class="fw-bold mb-0">Travel Expense</h4>
							<p>Manage and track expenses related to vehicle travel.</p>
						</div>
					</div>
					<div class="col d-flex flex-column align-items-center">
						<h1 class="ri-checkbox-circle-fill text-success"></h1>
						<div class="text-center">
							<h4 class="fw-bold mb-0">Trip Sheet</h4>
							<p>Keep detailed records of each trip for better management and analysis.</p>
						</div>
					</div>
					<div class="col d-flex flex-column align-items-center">
						<h1 class="ri-checkbox-circle-fill text-success"></h1>
						<div class="text-center">
							<h4 class="fw-bold mb-0">Document Alert</h4>
							<p>Receive timely alerts for important document renewals and compliance.</p>
						</div>
					</div>
					<div class="col d-flex flex-column align-items-center">
						<h1 class="ri-checkbox-circle-fill text-success"></h1>
						<div class="text-center">
							<h4 class="fw-bold mb-0">Ledger</h4>
							<p>Track transactions and payments for drivers and vendors accurately.</p>
						</div>
					</div>
					<div class="col d-flex flex-column align-items-center">
						<h1 class="ri-checkbox-circle-fill text-success"></h1>
						<div class="text-center">
							<h4 class="fw-bold mb-0">Reports</h4>
							<p>Insights for informed <br />decisions.</p>
						</div>
					</div>
				</div>
				<hr class="border border-success" />
			</div>
			<div class="container mb-5">
				<h2 class="fw-bold fst-italic  text-center main-color">OUR PRODUCTS</h2>
				<hr class="border border-success" />
			</div>
			<Vproducts />
		</>
	)
}

export default FLeetmanagement